<template>
  <div class="about">
      <!-- <Breadcrumb></Breadcrumb> -->
      <el-container>
            <el-aside width="210px">
                    <!-- <MyHeader></MyHeader> -->
                    <Asider></Asider>
            </el-aside>
            <el-main :class="ClassFlag==1?'RightContent':''">
                <router-view  @getCode='getCode'></router-view>
            </el-main>
  </el-container>
  </div>
</template>

<script>
// const Breadcrumb = ()=>import('components/common/Breadcrumb.vue')
// const MyHeader = ()=>import('components/my/Header.vue')
const Asider = ()=>import('components/my/Asider.vue')

  export default {
    name:"My",
    data() {
      return {
        ClassFlag:0,
      }
    },
    methods:{
      getCode(value){
        console.log(value);
        this.ClassFlag=1
      },
  },
   created(){
     
     
    },
    mounted(){
     
    },
    components:{
        // Breadcrumb,
        // MyHeader,
        Asider
    }
  }
</script>
<style lang="less" scoped>
@import '~style/index.less';
.about{
  width: 1200px;
   margin:0px auto;
}
.el-main {
    margin-left: 20px!important;
    padding:  20px;
    background: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow: unset!important;
    .RightContent{
      padding: 0 !important;
      background: #f6f6f6;
    }
}
 
</style>
